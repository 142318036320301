import { GetServerSidePropsContext, GetServerSidePropsResult } from 'next'
import { IKoodoGenericPageTemplateFields } from 'contentful-types'

import { getServerSideLocale } from '@telus/next-locale-utilities'
import koodoAnalyticsScripts from '@telus/koodo-analytics-scripts'

import { Language, Locale, Province, removeLocaleFromSlug } from '@/siteBuilder/locale'

import '@telus/koodo-header-footer/dist/index.css'
// TODO: importing kds.css overrides the UDS TermsAndConditions component such that it does not render ordered list numbers
import '@telus/kds/dist/css/kds.css'

import { getContentfulLocale } from '@/siteBuilder/locale/getContentfulLocale'
import { getGenericPageTemplateFromSlug } from '@/siteBuilder/utils/getGenericPageTemplateFromSlug'
import { KoodoGenericPage, KoodoGenericPageProps } from '@/siteBuilder/components/KoodoGenericPage'

import sitebuilderConfig from '@/sitebuilder.config'
import { ISiteBuilderEntry } from '@/siteBuilder/utils/types/ISiteBuilderEntryTypes'
import { getGlobalElementContent, removeMetadata } from '@telus/koodo-shared-utils'
import { ValidExcludes, handleExcludesQueryParam } from '../../utils/handleExcludesQueryParam'
import { promiseAllProps } from '../../utils/promiseAllProps'
import { AnalyticsScript } from '../../siteBuilder/lineOfBusiness/types'
import { handleRedirect } from '@/siteBuilder/utils/handleRedirect'

async function getKoodoGPT(slug: string, ctx: GetServerSidePropsContext, locale: string, correlationId: string) {
  const forceContentfulFallbackCache = (ctx.req.headers['force-contentful-fallback-cache'] as string) || 'false'

  const genericPageTemplate = await getGenericPageTemplateFromSlug(
    slug === '' ? '/' : slug,
    sitebuilderConfig.space,
    ctx.req.cookies,
    locale,
    correlationId,
    forceContentfulFallbackCache,
    'koodoGenericPageTemplate'
  )
  return genericPageTemplate as ISiteBuilderEntry<IKoodoGenericPageTemplateFields>
}

export async function getServerSideProps(
  ctx: GetServerSidePropsContext
): Promise<GetServerSidePropsResult<KoodoGenericPageProps>> {
  const { params } = ctx
  const slug = removeLocaleFromSlug(params?.slug)
  let correlationId = ''
  // correlation id is found in different places depending on if nextjs is run via npm run dev or npm start
  // Not sure why :(
  if (ctx.req.headers['correlation-id']) {
    // Required in dev
    correlationId = ctx.req.headers['correlation-id'] as string
  } else if (ctx.res?.getHeader('correlation-id')) {
    // Required in prod
    correlationId = ctx.res?.getHeader('correlation-id') as string
  }

  const serverSideLocale = getServerSideLocale(ctx)
  const locale: Locale = {
    language: serverSideLocale.lang as Language,
    province: serverSideLocale.prov as Province,
    country: 'CA',
  }

  const contentfulLocale = getContentfulLocale(locale)

  const requests = {
    koodoGenericPageTemplate: getKoodoGPT(slug, ctx, contentfulLocale, correlationId),
    koodoGlobalElementsMetadata: getGlobalElementContent({
      accessToken: process.env.CONTENTFUL_KOODO_ACCESS_TOKEN,
      host: process.env.CONTENTFUL_KOODO_HOST,
      locale: `${locale.language}-CA`,
      environment: process.env.CONTENTFUL_KOODO_ENVIRONMENT_ID,
      trackingApplicationName: 'site-builder-v2',
    }),
  }

  const { koodoGlobalElementsMetadata, koodoGenericPageTemplate } = await promiseAllProps(requests)
  const koodoGlobalElements = removeMetadata(koodoGlobalElementsMetadata as Record<string, unknown>)

  // Handle redirect
  if (koodoGenericPageTemplate.fields.redirect) {
    return {
      redirect: handleRedirect(koodoGenericPageTemplate.fields.redirect.fields),
    }
  }

  // Check the exclude query param to see if we should exclude any functionality
  // Supported excludes are 'ge' and 'analytics'
  // They may be passed together as a comma separated list
  // Ex. ?exclude=ge,analytics
  let excludes: Partial<Record<ValidExcludes, boolean>> = null
  if (process.env.APP_ENV !== 'production') {
    excludes = handleExcludesQueryParam(ctx.query?.excludes as string)
  }

  let analyticsEnvironment: string
  switch (process.env.APP_ENV) {
    case 'production':
      analyticsEnvironment = 'production'
      break
    default:
      analyticsEnvironment = 'staging'
      break
  }

  const analyticsScript: AnalyticsScript = {
    src: koodoAnalyticsScripts.getTagManagerScriptPath(analyticsEnvironment, 'commerce') as string,
    defer: true,
  }

  return {
    props: {
      koodoGenericPageTemplate,
      koodoGlobalElements,
      locale,
      brand: 'koodo',
      excludes,
      analyticsScripts: [analyticsScript],
    },
  }
}

export default function KoodoPage({
  koodoGenericPageTemplate,
  koodoGlobalElements,
  locale,
  excludes,
  analyticsScripts,
}: KoodoGenericPageProps) {
  return (
    <KoodoGenericPage
      koodoGenericPageTemplate={koodoGenericPageTemplate}
      koodoGlobalElements={koodoGlobalElements}
      locale={locale}
      brand="koodo"
      excludes={excludes}
      analyticsScripts={analyticsScripts}
    />
  )
}
