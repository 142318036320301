import { useEffect } from 'react'
import merge from 'deepmerge'
import styled, { createGlobalStyle } from 'styled-components'
import { IBreadcrumbs, IKoodoGenericPageTemplateFields } from '@/contentful-types'
import { BlockRenderer } from '../renderer/contentful/BlockRenderer'
import Legal from './Legal'
import Head from './Head/Head'
import { ISiteBuilderEntry } from '../utils/types/ISiteBuilderEntryTypes'
import { Locale } from '../locale'
import { ValidExcludes } from '../../utils/handleExcludesQueryParam'
import { AnalyticsScript } from '../lineOfBusiness/types'
import { pageKoodoAnalytics } from '../utils/koodoAnalyticsObject'

const LegalWrapper = styled.span`
  & sup {
    vertical-align: super;
  }
`

// kds.css overrides the ol list style to none, so we need to override it back to decimal for Koodo Footnote
const GlobalStyle = createGlobalStyle`
  div[data-testid="uds-footnote"] ol {
    list-style: decimal;
  }
`

const renderBreadcrumbs = (breadcrumbs: IBreadcrumbs) => <BlockRenderer block={breadcrumbs} fieldPath={'breadcrumbs'} />

export type KoodoGenericPageProps = {
  koodoGenericPageTemplate: ISiteBuilderEntry<IKoodoGenericPageTemplateFields>
  koodoGlobalElements: Record<string, any>
  locale: Locale
  brand: 'koodo'
  analyticsScripts: AnalyticsScript[]
  excludes: Partial<Record<ValidExcludes, boolean>>
}

export function KoodoGenericPage({ koodoGenericPageTemplate, locale }: KoodoGenericPageProps) {
  const { slug, breadcrumbs, seo, blocks } = koodoGenericPageTemplate.fields
  const { province, language } = locale
  const breadcrumbsComponent = breadcrumbs !== undefined && renderBreadcrumbs(breadcrumbs)

  // Analytics
  useEffect(() => {
    // Initializing window.dataLayer
    const dataLayerConfig = pageKoodoAnalytics(koodoGenericPageTemplate.fields)

    // Default dataLayer config
    const defaultDataLayerConfig = {
      page: {
        name: slug,
        province: province,
        language: language,
      },
      event: {},
      user: {},
    }

    window.dataLayer = merge(defaultDataLayerConfig, dataLayerConfig)
  }, [koodoGenericPageTemplate.fields, province, language, slug])

  return (
    <>
      <Head title={seo?.fields?.title} language={language} seo={seo?.fields} slug={slug} />
      {breadcrumbsComponent}
      <GlobalStyle />
      <LegalWrapper>
        <BlockRenderer block={blocks} fieldPath={'blocks'} />
        <Legal />
      </LegalWrapper>
    </>
  )
}
