import { IKoodoGenericPageTemplateFields } from 'contentful-types'

type Page = {
  name: string
  type: string
  url: string
  timehour: string
  timedayofweek: string
  timeweekdayend: string
  section: string
  category: string
  categorysub1: string
  categorysub2?: string
  categorysub3?: string
  server?: string
}

type Event = {
  detail: {
    intcmp: string
  }
}

export const pageKoodoAnalytics = (koodoGenericPage: IKoodoGenericPageTemplateFields) => {
  const { koodoDataLayer } = koodoGenericPage
  const { slug } = koodoGenericPage

  const type = 'site_builder-landing_page'
  const server = 'https://www.koodomobile.com'

  const weekdays = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday']
  const date = new Date()
  const dayOfWeek = weekdays[date.getDay()]
  const hour = new Date().getHours()
  const minute = new Date().getMinutes()
  const timeHour = [hour, minute].join(':')
  const weekdayType = dayOfWeek === 'saturday' || dayOfWeek === 'sunday' ? 'weekend' : 'weekday'

  const section =
    typeof koodoDataLayer?.fields?.section === 'string'
      ? koodoDataLayer?.fields?.section.split(' ').join('_').toLowerCase()
      : ''
  const url = `https://www.koodomobile.com/${slug}`
  const category =
    typeof koodoDataLayer?.fields?.category === 'string'
      ? koodoDataLayer?.fields?.category.split(' ').join('_').toLowerCase()
      : ''

  const slugSection = slug.split('/')
  const categorySub1 = slugSection.length > 0 ? slugSection[0] : ''
  const categorySub2 = slugSection.length > 1 ? slugSection[1] : ''
  const categorySub3 = slugSection.length > 2 ? slugSection[2] : ''
  const name = slug !== undefined && category !== '' ? `${category}/${slug}` : ''

  const page: Page = {
    name,
    type,
    url,
    timehour: timeHour,
    timedayofweek: dayOfWeek,
    timeweekdayend: weekdayType,
    section,
    category,
    categorysub1: categorySub1,
    categorysub2: categorySub2,
    categorysub3: categorySub3,
    server,
  }

  const urlParams = new URLSearchParams(window.location.search)
  const intcmpValue = urlParams.get('INTCMP') || urlParams.get('intcmp')

  const event: Event = {
    detail: {
      intcmp: intcmpValue,
    },
  }

  return { page, event }
}
